import sendErrorLogsToServer from "./sendErrorLogsToServer";

export default async function errorParsing() {

  if (JSON.parse(sessionStorage.getItem("jwt"))?.["token"]) {

    const checkLastErrors = async () => {
      const result = new Promise((res, rej) => {
        const errorsSaved = JSON.parse(sessionStorage.getItem("errorLogs"));
        const similarErrors = errorsSaved.splice(-2);

        if (similarErrors.length > 1) {
          const errorone = similarErrors[0]?.message;
          const errortwo = similarErrors[1]?.message;
          // Split the strings into arrays of words
          const errorOne = errorone?.split(" ");
          const errorTwo = errortwo?.split(" ");
          // Find the common words
          const commonErrors = errorOne?.filter((word) =>
            errorTwo?.includes(word)
          );

          if (commonErrors.length > 0) {
            errorsSaved.push(similarErrors[1]);
            sessionStorage.setItem(
              "errorLogs",
              JSON.stringify(errorsSaved)
            );
          }
        }
        res(null);
      });

      return result;
    };

    await checkLastErrors();
    const errorLogs = JSON.parse(sessionStorage.getItem("errorLogs"));
    const currentErrorLog = errorLogs.pop();
    sessionStorage.setItem("errorLogs", JSON.stringify(errorLogs));
    sendErrorLogsToServer(currentErrorLog);
  }
}