import axios from "axios";
import config from "../../config";

export default axios.create({
  baseURL: config.apiRootUrl,
  headers: {
    "Content-type": "application/json",
    "x-api-key": config.apiRootKeyTenant
  }
});

// export default axios.create({
//   baseURL: "https://sandbox.deseo.tech:443/v1",
//   headers: {
//     "Content-type": "application/json",
//     "x-api-key": "23cb09e4-2f62-4ee6-8ee4-d14912f8ec67"
//   }
// });